import { createUseStyles } from 'react-jss';

export default createUseStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 'var(--_ui5-v1-19-0_dialog_footer_height)',
    borderTop: '1px solid var(--sapPageFooter_BorderColor)',
    paddingLeft: 'var(--_ui5-v1-19-0_popup_header_footer_padding_m_l)',
    paddingRight: 'var(--_ui5-v1-19-0_popup_header_footer_padding_m_l)',
    '@media (max-width: 599px)': {
      paddingLeft: 'var(--_ui5-v1-19-0_popup_header_footer_padding_s)',
      paddingRight: 'var(--_ui5-v1-19-0_popup_header_footer_padding_s)',
    },
  },
});
