import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import {
  SideNavigation,
  SideNavigationItem,
  SideNavigationSubItem,
} from '@ui5/webcomponents-react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { featureToggles } from 'src/common/feature-toggles';
import { getURLParam } from '../../common/Utils';
import { buildSideNavListItems } from './Utils';
import styles from './SidePanel.styles';
import { SCREEN_L_SIZE } from '../../common/constants';

const { getConfig, getFeatureToggle } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = false;

const useStyles = createUseStyles(styles);

const isDisable = item => !!item?.disabled;

const getConfigValueByProp = (config, propName, propValue, valueName) => {
  for (const comp of config.components || []) {
    for (const nav of comp?.config?.sidenav || []) {
      if (
        nav[propName] === propValue ||
        (propName === 'router' && nav[propName] !== '' && propValue?.startsWith(nav[propName]))
      ) {
        return {
          appName: comp.config.app,
          [valueName]: nav[valueName],
          disabled: isDisable(nav),
        };
      } else if (Array.isArray(nav?.items) && nav.items.length > 0) {
        for (const item of nav.items) {
          if (item[propName] === propValue || propValue?.startsWith(item[propName])) {
            return {
              appName: comp.config.app,
              [valueName]: item[valueName],
              disabled: isDisable(item),
            };
          }
        }
      } else if (comp.config.routers.includes(propValue)) {
        return {
          appName: comp.config.app,
          [valueName]: nav[valueName],
          disabled: isDisable(nav),
        };
      }
    }
  }
};

function SidePanel(props) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState('home');
  const { t } = useTranslation();
  const { showMenu } = props.common || {};

  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);
  const built_in_Support_FF = getFeatureToggle(featureToggles.BUILT_IN_SUPPORT.name);

  // set initial sidePanel active route
  useEffect(() => {
    const pathname = props.history.location.pathname;
    handleSetActiveApp(props.config, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // subscribe for subsequent path changes
  useEffect(() => {
    const unlisten = props.history?.listen(({ pathname }) =>
      handleSetActiveApp(props.config, pathname),
    );

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.config]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
  }, []);

  const handleSetActiveApp = useCallback((config, pathname) => {
    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg && cfg.id) {
        setSelectedId(cfg.id);
      } else if (pathname === '/support-page') {
        setSelectedId('support');
      } else {
        setSelectedId('home');
      }
    }
  }, []);

  const handleNavigationItemClick = e => {
    const selected = e.target.selected;
    if (!selected) {
      return;
    }
    handleSelectChange(e.target.id);
  };

  const handleSelectChange = selectedId => {
    if (!selectedId) {
      return;
    }
    const config = props.config;

    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';

    if (selectedId === 'home' && !homeDisabled) {
      setSelectedId('home');
      // comment out the line below since it shows blank while back to home
      // props.history.push('/' + testingLngParam);
      props.history.push('/irmo-analytics/home' + testingLngParam);
    } else if (selectedId === 'support') {
      setSelectedId('support');
      props.history.push('/support-page' + testingLngParam);
    } else if (selectedId === 'disposition') {
      setSelectedId('disposition');
      props.history.push('/rm-disposition/pkg-identification' + testingLngParam);
      if (props.common.isStopRefreshDisposition) return;
      props.actions.setIsStopRefreshDisposition();
      props.history.push('/rm-disposition/pkg-identification' + testingLngParam);
    } else {
      setSelectedId(selectedId);
      const cfg = getConfigValueByProp(config, 'id', selectedId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        props.history.push(cfg.router + testingLngParam);
      }
    }
    // when the width is less than 1024, after the selection hide the menu
    if (window.innerWidth < SCREEN_L_SIZE) {
      props.actions.hideMenu();
    }
  };

  const renderFixedItems = () => {
    const listItems = [
      {
        id: 'support',
        text: 'SideNav_Support',
        isFixed: true,
        icon: 'headset',
        router: '/support-page',
        order: 10,
        skipPermission: true,
      },
    ];

    return listItems.map(item => {
      if (Array.isArray(item?.items) && item.items.length > 0) {
        return (
          <SideNavigationItem
            onClick={handleNavigationItemClick}
            selected={item.id === selectedId}
            expanded={!!item.items.find(child => child.id === selectedId)}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            data-testid={item.id}
            title={t(item.text)}
          />
        );
      } else {
        return (
          <SideNavigationItem
            selected={item.id === selectedId}
            onClick={handleNavigationItemClick}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            // style={{ backgroundColor: 'red !important' }}
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            data-testid={item.id}
            title={t(item.text)}
          />
        );
      }
    });
  };

  const renderNavItems = () => {
    const config = props.config;

    const initListItems = [
      {
        id: 'home',
        text: 'SideNav_Home',
        icon: 'home',
        router: '/',
        order: 10,
        disabled: homeDisabled,
        skipPermission: true,
      },
    ];

    const listItems = buildSideNavListItems({
      initListItems,
      components: config?.components,
      getFeatureToggle,
    })
      .filter(i => {
        return (
          (Array.isArray(i?.items) && i.items.filter(c => checkPermissions(c)).length > 0) ||
          checkPermissions(i)
        );
      })
      .sort((a, b) => a.order - b.order);

    return listItems.map(item => {
      if (Array.isArray(item?.items) && item.items.length > 0) {
        return (
          <SideNavigationItem
            wholeItemToggleable
            onClick={handleNavigationItemClick}
            selected={item.id === selectedId}
            expanded={!!item.items.find(child => child.id === selectedId)}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            data-testid={item.id}
            title={t(item.text)}
          >
            {item.items
              .filter(i => checkPermissions(i))
              .map(child => (
                <SideNavigationSubItem
                  selected={child.id === selectedId}
                  onClick={handleNavigationItemClick}
                  key={child.id}
                  className={
                    isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                  }
                  text={t(child.text)}
                  icon={child.icon}
                  id={child.id}
                  data-id={child.id}
                  data-testid={child.id}
                  title={t(child.text)}
                />
              ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            selected={item.id === selectedId}
            onClick={handleNavigationItemClick}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            // style={{ backgroundColor: 'red !important' }}
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            data-testid={item.id}
            title={t(item.text)}
          />
        );
      }
    });
  };

  const checkPermissions = item => {
    if (item.hasOwnProperty('skipPermission') && item.skipPermission) {
      return true;
    }

    const configPermissions = item['permissions'];

    if (configPermissions) {
      const permissions = Array.isArray(configPermissions)
        ? configPermissions.map(p => p.toUpperCase())
        : [];
      if (item?.conjunction === 'OR') {
        return permissions.length > 0 && permissions.some(p => currentUserPermissions.includes(p));
      }

      return permissions.length > 0 && permissions.every(p => currentUserPermissions.includes(p));
    } else {
      return false;
    }
  };

  return (
    <SideNavigation
      className={classes.sideNavigation}
      collapsed={!showMenu}
      selectedId={selectedId}
      style={{ height: '100%', fontSize: '14px' }}
      onSelectionChange={e => {
        handleSelectChange(e.detail.item.id);
      }}
      fixedItems={built_in_Support_FF ? null : renderFixedItems()}
      data-testid="rm-sidenav"
    >
      {renderNavItems()}
    </SideNavigation>
  );
}

SidePanel.propTypes = {
  actions: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
