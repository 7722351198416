const config = require('./config.json');
const testUrl = config.testUrl;
const productUrl = config.productUrl;

export const getBuiltInSupportConfig = () => {
  if (
    window.location.hostname === config.usHostName ||
    window.location.hostname === config.euHostName
  )
    return { url: productUrl, env: 'live' };
  else return { url: testUrl, env: 'test' };
};
