const config = require('./config.json');

export const devUrl = config.devUrl;

export const prodUrl = config.prodUrl;

export const getWebAssistantUrl = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'irmo.rm-dev3.rm-dev.eurekacloud.io':
      return devUrl;
    default:
      return prodUrl;
  }
};
