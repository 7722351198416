import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import Header from './Header';
import SidePanel from './SidePanel';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import { getURLParam } from '../../common/Utils';

const { Spinner } = eureka.components;
const { eventBus } = eurekaMgrs;

class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    // settings: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // when the default layout need to hide the navigation panel, dispatch the toggle-btn-visible to hide the toggle button
    if (this.props.hideNav) {
      eventBus.emit('toggle-menu-btn-visible');
    }
    const testingLngCode = getURLParam(this.props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';
    if (this.props.match.url === '/rm-disposition/pkg-identification' && this.props.hideNav) {
      actions.setIsStopRefreshDisposition();
      if (this.props.common.isStopRefreshDisposition) return;
      setTimeout(() => {
        this.forceUpdate();
        this.props.history.push('/rm-disposition/pkg-identification' + testingLngParam);
      }, 100);
    }
  }

  render() {
    const { showMenu } = this.props.common;
    return (
      <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
        <div className="shell-header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              user={this.props.user}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            {this.props.hideNav ? null : (
              <div
                className={
                  'fd-app__navigation fd-app__navigation--vertical' +
                  (showMenu ? ' show-menu ' : ' hide-menu ')
                }
              >
                <Suspense fallback={<Spinner />}>
                  <SidePanel history={this.props.history} config={this.props.config} />
                </Suspense>
              </div>
            )}

            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
        <OptionalMessageBox />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
