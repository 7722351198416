import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    '@media (max-width: 599px)': {
      padding: '1rem var(--_ui5-v1-19-0_popup_content_padding_s)',
    },
    padding: '1rem var(--_ui5-v1-19-0_popup_content_padding_m_l)',
  },
  logoContainer: {
    width: '130px',
    height: '60px',
    marginBottom: '20px',
  },
  sapLogo: {
    width: '100%',
  },
  title: {
    marginBottom: '28px',
    fontSize: '22px',
  },
  links: {
    width: '200px',
  },
  link: {
    textDecoration: 'none',
    fontSize: '14px',
  },
  copyrightContainer: {
    margin: '28px 0px 60px',
  },
  versionText: {
    marginBotton: '4px',
    fontSize: '14px',
    color: '#32363A',
  },
  buttonContainer: {
    height: 'var(--_ui5-v1-19-0_dialog_footer_height)',
    borderTop: '1px solid var(--sapPageFooter_BorderColor)',
    paddingLeft: 'var(--_ui5-v1-19-0_popup_header_footer_padding_m_l)',
    paddingRight: 'var(--_ui5-v1-19-0_popup_header_footer_padding_m_l)',
    '@media (max-width: 599px)': {
      paddingLeft: 'var(--_ui5-v1-19-0_popup_header_footer_padding_s)',
      paddingRight: 'var(--_ui5-v1-19-0_popup_header_footer_padding_s)',
    },
  },
  button: {
    marginRight: '8px',
  },
});
